﻿export default class BubbleInfoBlock {

    init() {
        $(function () {
            class BubbleExpander {

                constructor() {

                    function getBubbleInfoHtml(selectedBubble, itemNr, bubbleBlockId) {
                        const bubbleHtml = selectedBubble.find(".bubble-info-expanded").html();
                        return "<div class=\"bubbleInfo bubbleInfoId" + bubbleBlockId + "\" data-item=\"" + itemNr + "\" style=\"opacity: 0; margin-top: 50px; margin-bottom: 0px;\">" + bubbleHtml + "</div>";
                    }

                    function placeBubbleInfo(selectedBubble, itemRow, itemNr, rowBreak, bubbleBlockId) {
                        $(rowBreak + itemRow).html(getBubbleInfoHtml(selectedBubble, itemNr, bubbleBlockId));
                    }

                    $(".bubble-info-bubble").on("click", function () {
                        const bubbleOrder = $(this).parent().data("order") + 1;
                        const bubbleBlockId = $(this).parent().data("id");
                        $(".itemRowBreak .bubbleBlockId" + bubbleBlockId).css("max-height", "10px");

                        $(".bubbleInfoWrapper" + bubbleBlockId + " .bubble-info-bubble").each(function () {
                            $(this).removeClass("chosen-bubble");
                        });

                        const smMod = bubbleOrder % 1 === 0 ? 1 : bubbleOrder % 1;
                        const mdMod = bubbleOrder % 2 === 0 ? 2 : bubbleOrder % 2;
                        const lgMod = bubbleOrder % 3 === 0 ? 3 : bubbleOrder % 3;

                        const smItemRow = bubbleOrder + (1 - smMod);
                        const mdItemRow = bubbleOrder + (2 - mdMod);
                        const lgItemRow = bubbleOrder + (3 - lgMod);

                        if ($(".bubbleInfo.bubbleInfoId" + bubbleBlockId).data("item") !== bubbleOrder) {
                            $(this).addClass("chosen-bubble");
                            
                            $(".bubbleInfo.bubbleInfoId" + bubbleBlockId).remove();
                            placeBubbleInfo($(this), smItemRow, bubbleOrder, ".bubbleBlockId" + bubbleBlockId + ".smRowBreak", bubbleBlockId);
                            placeBubbleInfo($(this), mdItemRow, bubbleOrder, ".bubbleBlockId" + bubbleBlockId + ".mdRowBreak", bubbleBlockId);
                            placeBubbleInfo($(this), lgItemRow, bubbleOrder, ".bubbleBlockId" + bubbleBlockId + ".lgRowBreak", bubbleBlockId);
                            
                            $(".bubbleInfo.bubbleInfoId" + bubbleBlockId).css("opacity", "1").css("margin-top", "50px").css("margin-bottom", "0px");
                            $(".smRowBreak" + smItemRow + ".bubbleBlockId" + bubbleBlockId).css("max-height", "1500px");
                            $(".mdRowBreak" + mdItemRow + ".bubbleBlockId" + bubbleBlockId).css("max-height", "1500px");
                            $(".lgRowBreak" + lgItemRow + ".bubbleBlockId" + bubbleBlockId).css("max-height", "1500px");

                        } else {
                            $(".itemRowBreak.bubbleBlockId" + bubbleBlockId).css("max-height", "10px");
                            $(".bubbleInfo.bubbleInfoId" + bubbleBlockId).remove();
                        }
                    });
                }    
            }

            new BubbleExpander();
        });
    }
}
﻿export default class ReadMoreButton {
    init() {
        this.readMoreButton();
    }

    readMoreButton() {

        var $button, $buttonArea, $textArea, totalHeight, maxHeight, $fadeArea, $readMore, $readLess;

        $(document).on('click', '.textblock-readmore-button .button', function () {

            totalHeight = 0;

            $button = $(this);
            $buttonArea = $button.parent();
            $textArea = $buttonArea.closest('.container').find('.textblock-readmore');
            $fadeArea = $textArea.find('.textblock-readmore-fade');
            $readMore = $buttonArea.find("a.button-readmore");
            $readLess = $buttonArea.find("a.button-readless");
            totalHeight = $textArea.prop('scrollHeight');
            maxHeight = $textArea.data('height');

            if ($readMore.css('display') === 'none') {

                $readLess.hide();
                $fadeArea.fadeIn();
                $textArea
                    .css({
                        // Set height to prevent instant jumpdown when max height is removed
                        "height": totalHeight,
                        "max-height": 9999
                    })
                    .animate({
                        "height": maxHeight
                    });

                // Show Read More
                $readMore.fadeIn();
            }
            else {

                $readMore.hide();
                $textArea
                    .css({
                        // Set height to prevent instant jumpdown when max height is removed
                        "height": $textArea.data('height'),
                        "max-height": 9999
                    })
                    .animate({
                        "height": totalHeight
                    });

                // Show Read Less
                $readLess.fadeIn();
                $fadeArea.fadeOut();
            }
            
            // prevent jump-down
            return false;

        });
        
    }
}
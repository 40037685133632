﻿export default class EventBlock {

    init() {
        $(function () {
            class EventNavigation {

                constructor() {
                    this.headerHeight = 100;
                    const self = this;

                    $(".event-nav-tab").on("click", function (e) {
                        if (!$(this).hasClass("chosen-day")) {
                            $(".event-nav-tab").removeClass("chosen-day").removeClass("active");
                            $(this).addClass("chosen-day");
                        }
                    });

                    $(".mobile-event-menu-toggle").on("click", function() {
                        self.toggleMobileEventMenu(event, $(this));
                    });
                }
                
                toggleMobileEventMenu(event, element) {
                    event.preventDefault();
                    $(".event-link-showHide").toggleClass("event-link-down");
                    $(".event-nav-tab").toggle();
                    var eventMenuTop = $(".event-nav-tabs").offset().top;
                    if ($(window).scrollTop() < eventMenuTop) {
                        $("html, body").animate({ scrollTop: eventMenuTop - 100 }, 600);
                    }
                }              
            }

            new EventNavigation();
        });
    }
}
﻿export default class NewsRoom {

    init() {
        $(function () {
            class NewsStickyNavigation {

                constructor() {
                    this.currentId = null;
                    this.currentTab = null;
                    this.tabContainerHeight = 54;
                    this.headerHeight = 100;
                    this.lastScroll = 0;
                    const self = this;
                    const path = window.location.pathname;
                    var segments = path.split("/");

                    $("#news-sticky-nav-mobile-header-wrapper").on("click", function() {
                        self.toggleMobileInvestorsMenu(event, $(this));
                    });

                    $(".news-inside-sticky a").on("click", function() {
                        self.closeMobileInvestorsMenu();
                    });

                    $(window).scroll(() => { this.onScroll(); });
                    $(window).resize(() => { this.onResize(); });
                    $("#searchterm").on('input', function () {
                        if ($(this).val().length >= 3) {
                            var searchSite = $(this).data("site");
                            $.ajax({
                                url: "/api/Search/Autocomplete" + searchSite,
                                data: { q: $(this).val(), resultCount: "5", lang: segments[3], market: segments[2], alias: segments[1] },
                                contentType: 'application/json; charset=utf-8',
                                success: function (response) {
                                    if (response.length > 0) {
                                        var searchResultHtml = '<div class="autocomplete-search-result">';
                                        var json = JSON.parse(response);
                                        $.each(json, function (index) {
                                            searchResultHtml += '<div class="row search-result-row">';
                                            searchResultHtml += '<div class="col-3"><div class="search-result-small-image" style="background-image: url(' + json[index].ImageURL + ');"></div></div>';
                                            searchResultHtml += '<div class="col-7 search-result-headline">' + json[index].Name + '</div>';
                                            searchResultHtml += '<div class="col-2"><a href="' + json[index].ContentURL.replace('/Search/', '/SE/') + '" class="mdi mdi-chevron-right"></a></div>';
                                            searchResultHtml += '</div>';
                                        });
                                        searchResultHtml += '</div>';
                                        $("#auto-complete-results").html(searchResultHtml);
                                    }
                                }
                            });
                        }
                    });

                    $(document).on('click',
                        '.search-result-row',
                        function () {
                            window.location.href = $(this).find('a').attr('href');
                        });

                    $(document).on('click',
                        function (e) {
                            if ($(e.target).is('#search-area, .autocomplete-search-result'))
                                return;
                            $(".autocomplete-search-result").hide();
                        });

                    $(document).on("click" , ".news-list-wrapper", function () {
                        window.location = $(this).data("link");
                    });
                }

                onScroll() {
                    if (document.getElementById("news-room-nav-tabs")) {
                        this.checkHeaderPosition();
                        this.lastScroll = $(window).scrollTop();
                    }
                }

                onResize() {
                    if (this.currentId) {
                        this.setSliderCss();
                    }
                }

                checkHeaderPosition() {
                    const offset = ($(".news-sticky-nav-tabs").offset().top + $(".news-sticky-nav-tabs").height() - this.tabContainerHeight) - this.headerHeight;
                    if ($(window).scrollTop() > this.lastScroll && $(window).scrollTop() > offset) {
                        $(".news-sticky-nav-tabs-container").removeClass("news-sticky-nav-tabs-container--top-first");
                        $(".news-sticky-nav-tabs-container").addClass("news-sticky-nav-tabs-container--top-second");
                    }
                    else if ($(window).scrollTop() < this.lastScroll && $(window).scrollTop() > offset) {
                        $(".news-sticky-nav-tabs-container").removeClass("news-sticky-nav-tabs-container--top-second");
                        $(".news-sticky-nav-tabs-container").addClass("news-sticky-nav-tabs-container--top-first");
                    }
                    else {
                        $(".news-sticky-nav-tabs-container").removeClass("news-sticky-nav-tabs-container--top-first");
                        $(".news-sticky-nav-tabs-container").removeClass("news-sticky-nav-tabs-container--top-second");
                    }
                }

                setSliderCss() {
                    if (this.currentTab) {
                        $(".news-sticky-nav-tab").removeClass("active");
                        this.currentTab.addClass("active");
                    }
                }

                toggleMobileInvestorsMenu(event, element) {
                    event.preventDefault();
                    $(".news-room-link-showHide").toggleClass("news-room-link-down");
                    $(".mobile-news-room-menu-toggle").slideToggle();
                    if ($(window).scrollTop() < 500) {
                        $("html, body").animate({ scrollTop: 500 }, 600);
                    }
                }

                closeMobileInvestorsMenu() {
                    $(".mobile-news-room-menu-toggle").slideUp();
                    $(".news-room-link-showHide").addClass("investors-link-down");
                }
            }

            new NewsStickyNavigation();
        });
    }
}
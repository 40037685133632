﻿export default class ReplicatedSite {
    // This Ajax-call also includes checking if a user is logged in. If not - it deletes the logged in-cookie if it exists.
    init() {

        function getCookie(cname) {
            const name = cname + "=";
            const decodedCookie = decodeURIComponent(document.cookie);
            const ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
        function setCookie(cname, cValue, exDays) {
            const d = new Date();
            d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cValue + ";" + expires + ";path=/";
        }

        $(function () {
            var cookieValue = $('.replicated-site-info').attr('id');
            if (getCookie('ReplicatedSiteWelcome') !== cookieValue) {
                $('.replicated-site-info').fadeIn('slow');
                $('#replicated-shadow-layer').addClass('is-visible');
                $('#kundo-chat').hide();
            } else {
                $('.replicated-image-small').fadeIn('slow');
            }
        });

        function CloseReplicated() {
            var cookieValue = $('.replicated-site-info').attr('id');
            $('.replicated-site-info').fadeOut('slow');
            $('.replicated-image-small').fadeIn('slow');
            $('#replicated-shadow-layer').removeClass('is-visible');
            setCookie('ReplicatedSiteWelcome', cookieValue, 7);
            $('#kundo-chat').show();
        }
        $(document).on('click', '#replicated-shadow-layer', function () {
            CloseReplicated();
        });
        $(document).on('click', '#close-replicated', function () {
            CloseReplicated();
        });

        $(document).on('click', '.replicated-contact.mdi.mdi-phone', function (event) {
            event.preventDefault();
            $('.replicated-telephone-show-more').fadeIn('slow');
        });

        $(document).on('click', '#close-replicated-show-more', function () {
            $('.replicated-telephone-show-more').fadeOut('slow');
        });

        $(document).on("click", "#copy-replicated-phone", function () {
            const text = $("#replicated-contact-text").html();
            console.log(text);
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    $("#copy-replicated-phone")
                        .removeClass("mdi-content-copy")
                        .addClass("mdi-check")
                        .css("color", "green");
                })
                .catch((err) => {
                    console.error(`Error copying text to clipboard: ${err}`);
                });
        });

        //Handle replicated site - Email

        $(document).on('click', '.replicated-contact.mdi.mdi-email-outline', function (event) {
            event.preventDefault();
            $('.replicated-email-show-more').fadeIn('slow');
        });

        $(document).on('click', '#close-replicated-show-more', function () {
            $('.replicated-email-show-more').fadeOut('slow');
        });

        $(document).on("click", "#copy-replicated-email", function () {
            const text = $("#replicated-contact-text-email").html();
            console.log(text);
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    $("#copy-replicated-email")
                        .removeClass("mdi-content-copy")
                        .addClass("mdi-check")
                        .css("color", "green");
                })
                .catch((err) => {
                    console.error(`Error copying text to clipboard: ${err}`);
                });
        });

        $(document).on('click', '.replicated-image-small', function () {
            $('.replicated-site-info').fadeIn('slow');
            $('.replicated-image-small').fadeOut('slow');
            $('#replicated-shadow-layer').addClass('is-visible');
            $('#kundo-chat').hide();
        });
    }
}
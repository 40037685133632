﻿export default class ZinzinoRenderMenu {
    init() {
        // Change the menu background image
        var lastTimeMouseMoved = "";
        var startImage;

        $(".uspContainer").find("h3").addClass("uspHeader");
        $(".uspContainer").find("h4").addClass("uspPayoff");
        $(".uspContainer").find("ul").addClass("usp-list");

        function changeImage(e, underline, fade) {
            lastTimeMouseMoved = new Date().getTime();
            var sourceImage = $(e).data("image");

            // Set timeout so the image wont change unless the pointer stops.
            setTimeout(function () {
                var currentTime = new Date().getTime();

                // Don't change the image unless the pointer is over the menu item and has stopped moving and the image is different from the current.
                if (!fade || (currentTime - lastTimeMouseMoved > 80)) {
                    $(".menuImageSprite").css("opacity", "0");
                    var usps = $(e).data("usps");
                    setTimeout(function () {
                        $(".uspContainer").addClass("d-none");
                        $("#uspContainer" + usps).removeClass("d-none");
                        if ($(".uspStorage").find("#uspContainer" + usps).length !== 0) {
                            $(".menuImageSprite").html($(".uspStorage").html());
                        } else {
                            $(".menuImageSprite").html("");
                            $(".menuImageSprite").css("background-image", "url(" + sourceImage + ")");
                        }
                        $(".menuImageSprite").css("opacity", "1");

                    }, fade ? 300 : 0); // Timeout for fade out/in image
                }
            }, fade ? 90 : 0); // A time difference of 90 ms if the mouse keeps moving
        }
        $(document).on("mousemove", '[data-role="MenuHoverImage"]', function () { changeImage(this, true, true); }).on("mouseleave", '[data-role="MenuHoverImage"]', function () { changeImage(startImage, true, true); });
        $(document).on("click", '[data-role="MenuClickImage"]', function () { changeImage(this, false, false); startImage = this; });

        $(document).on('click', '.is-third-level-link', function () {
            var parentElement = $(this).closest('.is-third-level');
            var navIsDesktop = parentElement.closest(".cd-nav").parent().hasClass("zzMainHeader");
            if (parentElement.data("movemenu") && navIsDesktop) {

                var menuPositionAfterThis = parentElement.closest(".menuPosition").next('.menuPosition').offset();
                var menuShouldMoveDown = menuPositionAfterThis !== undefined ? menuPositionAfterThis.left === parentElement.offset().left : false;

                var oldTop = parseInt(parentElement.closest(".menuPosition").next('.menuPosition').css('top'), 10);
                var oldHeight = parseInt(parentElement.closest(".menuPosition").css('height'), 10);
                var move = parentElement.data("movemenu") * 30;

                if (parentElement.closest(".menuPosition").hasClass('menuIsMoved')) {
                    if (menuShouldMoveDown) {
                        parentElement.closest(".menuPosition").next('.menuPosition').animate({ top: (oldTop - move) + 'px' });
                    }
                    parentElement.closest(".menuPosition").css('height', (oldHeight - move) + 'px');
                    parentElement.closest(".menuPosition").removeClass('menuIsMoved');
                } else {
                    if (menuShouldMoveDown) {
                        parentElement.closest(".menuPosition").next('.menuPosition').animate({ top: (oldTop + move) + 'px' });
                    }
                    parentElement.closest(".menuPosition").css('height', (oldHeight + move) + 'px');
                    parentElement.closest(".menuPosition").addClass('menuIsMoved');
                }
            }
        });

        $(document).on('mouseenter', '.mightOverflow', function () {
            var $this = $(this);
            if (this.offsetWidth < this.scrollWidth && !$this.attr('title')) {
                $this.attr('title', $this.text());
            }
        });
        $(document).ready(function ($) {
            $('.cd-primary-nav').addClass('nav-is-hidden');
            //if you change this breakpoint in the style.css file (or _layout.scss if you use SASS), don't forget to update this value as well
            var MqL = 1170;
            //move nav element position according to window width
            moveNavigation();
            $(window).on('resize', function () {
                (!window.requestAnimationFrame) ? setTimeout(moveNavigation, 300) : window.requestAnimationFrame(moveNavigation);
            });

            //mobile - open lateral menu clicking on the menu icon
            $(document).on('click', '.cd-nav-trigger', function (event) {
                event.preventDefault();
                $('.cd-primary-nav').removeClass('nav-is-hidden');
                if ($('.cd-main-content').hasClass('nav-is-visible')) {
                    closeNav();
                    $('.cd-overlay').removeClass('is-visible');
                } else {
                    $(this).addClass('nav-is-visible');
                    $('.cd-primary-nav').addClass('nav-is-visible');
                    $('.cd-main-header').addClass('nav-is-visible');
                    $('.cd-main-content').addClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                        $('body').addClass('overflow-cart-hidden');
                    });
                    $('.cd-primary-nav').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {

                    });
                    toggleSearch('close');
                    $('.cd-overlay').addClass('is-visible');
                }
            });

            //open search form
            $('.cd-search-trigger').on('click', function (event) {
                event.preventDefault();
                toggleSearch();
                closeNav();
            });

            //close lateral menu on mobile 
            $(document).on('swiperight', '.cd-overlay', function () {
                if ($('.cd-primary-nav').hasClass('nav-is-visible')) {
                    closeNav();
                    $('.cd-overlay').removeClass('is-visible');
                }
            });
            $(document).on('swipeleft', '.nav-on-left .cd-overlay', function () {
                if ($('.cd-primary-nav').hasClass('nav-is-visible')) {
                    closeNav();
                    $('.cd-overlay').removeClass('is-visible');
                }
            });
            $(document).on('click', '.cd-overlay', function () {
                closeNav();
                toggleSearch('close');
                $('.cd-overlay').removeClass('is-visible');
            });


            //prevent default clicking on direct children of .cd-primary-nav 
            $(document).on('click', '.cd-primary-nav .has-children a.openSubMenu', function (event) {
                event.preventDefault();
            });
            //open submenu
            $(document).on('click', '.has-children a.openChildrenMobile', function (event) {
                if (!checkWindowWidth()) { event.preventDefault(); }
                var selected = $(this);
                if (selected.next('ul').hasClass('is-hidden')) {
                    //desktop version only
                    selected.addClass('selected').next('ul').removeClass('is-hidden').end().parent('.has-children').parent('ul').addClass('moves-out');
                    selected.parent('.has-children').siblings('.has-children').children('ul').addClass('is-hidden').end().children('a').removeClass('selected');
                    if (selected.hasClass('openSubMenu')) {
                        $('.cd-overlay').addClass('is-visible');
                    }

                } else {
                    selected.removeClass('selected').next('ul').addClass('is-hidden').end().parent('.has-children').parent('ul').removeClass('moves-out');
                    if (selected.hasClass('openSubMenu')) {
                        $('.cd-overlay').removeClass('is-visible');
                    }
                }
                toggleSearch('close');
            });

            //submenu items - go back link
            $(document).on('click', '.go-back', function () {
                $(this).parent('ul').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('moves-out');
            });

            function closeNav() {
                $('.cd-nav-trigger').removeClass('nav-is-visible');
                $('.cd-main-header').removeClass('nav-is-visible');
                $('.cd-primary-nav').removeClass('nav-is-visible');
                $('.has-children ul').addClass('is-hidden');
                $('.has-children a').removeClass('selected');
                $('.moves-out').removeClass('moves-out');
                $('.cd-main-content').removeClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                    $('body').removeClass('overflow-cart-hidden');
                });
                $('.cd-overlay').removeClass('is-visible');
            }

            function toggleSearch(type) {
                if (type == "close") {
                    //close serach 
                    $('.cd-search').removeClass('is-visible');
                    $('.cd-search-trigger').removeClass('search-is-visible');
                    $('.cd-overlay').removeClass('search-is-visible');
                } else {
                    //toggle search visibility
                    $('.cd-search').toggleClass('is-visible');
                    $('.cd-search-trigger').toggleClass('search-is-visible');
                    $('.cd-overlay').toggleClass('search-is-visible');
                    if ($(window).width() > MqL && $('.cd-search').hasClass('is-visible')) $('.cd-search').find('input[type="search"]').focus();
                    ($('.cd-search').hasClass('is-visible')) ? $('.cd-overlay').addClass('is-visible') : $('.cd-overlay').removeClass('is-visible');
                }
            }

            function checkWindowWidth() {
                //check window width (scrollbar included)
                var e = window,
                    a = 'inner';
                if (!('innerWidth' in window)) {
                    a = 'client';
                    e = document.documentElement || document.body;
                }
                if (e[a + 'Width'] >= MqL) {
                    return true;
                } else {
                    return false;
                }
            }

            function moveNavigation() {
                var navigation = $('.cd-nav');
                var cart = $('.zzPreHeaderCart');
                var desktop = checkWindowWidth();
                var login = $('.zzPreHeaderLogin');
                var signOut = $('.zzSignOut');
                if (desktop) {
                    if ($('.cd-overlay').hasClass('is-visible') && !$('#cd-primary-nav').hasClass('moves-out')) {
                        $('.cd-overlay').removeClass('is-visible');
                    }
                    signOut.css("display", "block");
                    navigation.detach();
                    navigation.insertBefore('.cd-header-buttons');
                    login.detach();
                    login.insertAfter('.zzPreHeaderSupport');
                } else {
                    if ($('.cd-overlay').hasClass('is-visible') && !$('#cd-primary-nav').hasClass('nav-is-visible')) {
                        $('#cd-primary-nav').addClass('nav-is-visible');
                    }
                    signOut.css("display", "none");
                    navigation.detach();
                    navigation.insertAfter('.cd-main-content');
                    login.detach();
                    login.insertBefore('.zzPreHeaderCountry');
                }
                navigation.css("display", "block");
            }

            $("[data-target='#language']").on('click', function () {
                if ($('.cd-primary-nav').hasClass('nav-is-visible')) {
                    closeNav();
                }
            });
        });

        //Quantity button for Add to cart
        function wcqib_refresh_quantity_increments() {
            jQuery("div.quantityBox:not(.buttons_added), td.quantityBox:not(.buttons_added)").each(function (a, b) {
                var c = jQuery(b);
                c.addClass("buttons_added"), c.children().first()
                    .before('<input type="button" value="-" class="minus" />'), c.children().last()
                        .after('<input type="button" value="+" class="plus" />');
            });
        }
        String.prototype.getDecimals || (String.prototype.getDecimals = function () {
            var a = this,
                b = ("" + a).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
            return b ? Math.max(0, (b[1] ? b[1].length : 0) - (b[2] ? +b[2] : 0)) : 0
        }), jQuery(document).ready(function () {
            wcqib_refresh_quantity_increments();
        }), jQuery(document).on("updated_wc_div", function () {
            wcqib_refresh_quantity_increments();
        }), jQuery(document).on("click", ".plus, .minus", function () {
            var a = jQuery(this).closest(".quantityBox").find(".qty"),
                b = parseFloat(a.val()),
                c = parseFloat(a.attr("max")),
                d = parseFloat(a.attr("min")),
                e = a.attr("step");
            b && "" !== b && "NaN" !== b || (b = 0), "" !== c && "NaN" !== c || (c = ""), "" !== d && "NaN" !== d || (d = 0), "any" !== e && "" !== e && void 0 !== e && "NaN" !== parseFloat(e) || (e = 1), jQuery(this).is(".plus") ? c && b >= c ? a.val(c) : a.val((b + parseFloat(e)).toFixed(e.getDecimals())) : d && b <= d ? a.val(d) : b > 0 && a.val((b - parseFloat(e)).toFixed(e.getDecimals())), a.trigger("change");
        });
    }
}
﻿export default class Footer {

    init() {
        $(function () {
            $(document).on("click", ".mobile-footleft", function () {
                if ($(window).width() < 768) {

                    if ($(this).hasClass("slideisup")) {
                        $(this).removeClass("slideisup");
                        $(this).find(".hide-on-mobile").slideDown(600);
                        $(this).find(".footer-link-showHide").removeClass("footer-link-down").addClass("footer-link-up");
                    } else {
                        $(this).addClass("slideisup");
                        $(this).find(".hide-on-mobile").slideUp(600);
                        $(this).find(".footer-link-showHide").removeClass("footer-link-up").addClass("footer-link-down");
                    }
                }
            });

            $('.footer-zinzino-test').on('click', function() {
                window.open('https://www.zinzinotest.com/');
                return false;
            });

            $(document).on("click", "#copy-share-link", function() {
                const text = $("#share-url-text").val();
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        $("#copy-share-link")
                            .removeClass("btn-primary")
                            .addClass("btn-success mdi mdi-check")
                            .html("");
                    })
                    .catch((err) => {
                        console.error(`Error copying text to clipboard: ${err}`);
                    });
            });
        });
    }
}
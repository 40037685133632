﻿export default class CartItems {
    // This Ajax-call also includes checking if a user is logged in. If not - it deletes the logged in-cookie if it exists.
    init() {
        $(function () {
            const currentUrl = window.location.href.toLowerCase();
            if (currentUrl.indexOf("/episerver") < 0) {
                const isPartnerWeb = currentUrl.indexOf("/partnerweb");
                var arr = currentUrl.split('/');
                var urlWithSegments = "/shop/" + arr[3] + "/" + arr[4] + "/" + arr[5] + "/login/GetLoginAndCartStatus";
                var url = isPartnerWeb > 0 ? urlWithSegments + "PartnerWeb" : urlWithSegments;
                $.ajax({
                    url: url,
                    dataType: "jsonp",
                    success: function (response) {
                        const itemsInCart = response.AmountItemsInCart;
                        if (itemsInCart > 0) {
                            $('.ItemsInCart').html('<div>' + itemsInCart + '</div>');
                        } else {
                            $('.ItemsInCart').html('');
                        }
                    }
                });
            }
        });

        $(document).on('click',
            '#previewCartCheckoutButton',
            function () {
                const checkoutModalUrlEnrollment = $(this).data('url');
                $("#cd-shadow-layer").click();
                $.getJSON(checkoutModalUrlEnrollment,
                    function (response) {
                        const responseJson = JSON.parse(response);
                        window.location = responseJson.Url;
                    });
            });
    }
}
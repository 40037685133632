﻿export default class ZinzinoCarousel {
    init() {

        // Carousel block

        $(".carousel-item", ".show-neighbors").each(function () {
            var next = $(this).next();
            if (!next.length) {
                next = $(this).siblings(":first");
            }
            next.children(":first-child").clone().appendTo($(this));
        }).each(function () {
            var prev = $(this).prev();
            if (!prev.length) {
                prev = $(this).siblings(":last");
            }
            prev.children(":nth-last-child(2)").clone().prependTo($(this));
        });



        // Brand ambassador carousel

        var itemsMainDiv = ('.MultiCarousel');
        var itemsDiv = ('.MultiCarousel-inner');
        var itemWidth = "";

        $('.brand-ambassador-recommended-products .carousel-control-prev, .brand-ambassador-recommended-products .carousel-control-next').on('click', function () {
            var condition = $(this).hasClass("carousel-control-prev");
            if (condition)
                click(0, this);
            else
                click(1, this);
        });

        ResCarouselSize();

        $(window).resize(function () {
            ResCarouselSize();
        });

        //this function define the size of the items
        function ResCarouselSize() {
            var incno = 0;
            var dataItems = ("data-items");
            var itemClass = ('.item');
            var id = 0;
            var btnParentSb = '';
            var itemsSplit = '';
            var sampwidth = $(itemsMainDiv).width();
            var bodyWidth = $('body').width();
            $(itemsDiv).each(function () {
                id = id + 1;
                var itemNumbers = $(this).find(itemClass).length;
                btnParentSb = $(this).parent().attr(dataItems);
                itemsSplit = btnParentSb.split(',');
                $(this).parent().attr("id", "MultiCarousel" + id);


                if (bodyWidth >= 768) {
                    incno = 2;
                    itemWidth = sampwidth / incno;
                }
                else {
                    incno = 1;
                    itemWidth = sampwidth / incno;
                }
                $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
                $(this).find(itemClass).each(function () {
                    $(this).outerWidth(itemWidth);
                });

                $(".carousel-control-prev").addClass("over");
                $(".carousel-control-next").removeClass("over");

            });
        }


        //this function used to move the items
        function ResCarousel(e, el, s) {
            var leftBtn = ('.carousel-control-prev');
            var rightBtn = ('.carousel-control-next');
            var translateXval = '';
            var divStyle = $(el + ' ' + itemsDiv).css('transform');
            var values = divStyle.match(/-?[\d\.]+/g);
            var xds = Math.abs(values[4]);
            if (e === 0) {
                translateXval = parseInt(xds) - parseInt(itemWidth * s);
                $(el).closest(".brand-ambassador-recommended-products").find(rightBtn).removeClass("over");

                if (translateXval <= itemWidth / 2) {
                    translateXval = 0;
                    $(el).closest(".brand-ambassador-recommended-products").find(leftBtn).addClass("over");
                }
            }
            else if (e === 1) {
                var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
                translateXval = parseInt(xds) + parseInt(itemWidth * s);
                $(el).closest(".brand-ambassador-recommended-products").find(leftBtn).removeClass("over");

                if (translateXval >= itemsCondition - itemWidth / 2) {
                    translateXval = itemsCondition;
                    $(el).closest(".brand-ambassador-recommended-products").find(rightBtn).addClass("over");
                }
            }
            $(el + ' ' + itemsDiv).css('transform', 'translateX(' + -translateXval + 'px)');
        }

        //It is used to get some elements from btn
        function click(ell, ee) {
            var Parent = "#" + $(ee).parent().find(".MultiCarousel").attr("id");
            var slide = $(Parent).attr("data-slide");
            ResCarousel(ell, Parent, slide);
        }
    }
}
﻿export default class Investors {

    init() {
        $(function () {
            class StickyNavigation {

                constructor() {
                    this.currentId = null;
                    this.currentTab = null;
                    this.tabContainerHeight = 54;
                    this.headerHeight = 100;
                    this.lastScroll = 0;
                    const self = this;
                    $(".sticky-nav-tab").click(function () {
                        self.onTabClick(event, $(this));
                    });
                    $("#financial-reports-year").on("change", function () {
                        self.changeFinancialReportsYear(event, $(this));
                    });
                    $("#press-releases-year").on("change", function () {
                        self.changePressReleasesYear(event, $(this));
                    });
                    $("#governance-reports-year").on("change", function () {
                        self.changeGovernanceReportsYear(event, $(this));
                    });
                    $("#general-meetings-year").on("change", function () {
                        self.changeGeneralMeetingsYear(event, $(this));
                    });

                    $("#sticky-nav-mobile-header-wrapper").on("click", function() {
                        self.toggleMobileInvestorsMenu(event, $(this));
                    });

                    $(".inside-sticky a").on("click", function() {
                        self.closeMobileInvestorsMenu();
                    });

                    $(window).scroll(() => { this.onScroll(); });
                    $(window).resize(() => { this.onResize(); });
                }

                onTabClick(event, element) {
                    event.preventDefault();
                    const scrollTop = $(element.attr("href")).offset().top - this.tabContainerHeight - this.headerHeight + 5;
                    $("html, body").animate({ scrollTop: scrollTop }, 600);
                }

                onScroll() {
                    if (document.getElementById("investor-nav-tabs")) {
                        this.checkHeaderPosition();
                        this.findCurrentTabSelector();
                        this.lastScroll = $(window).scrollTop();
                    }
                }

                onResize() {
                    if (this.currentId) {
                        this.setSliderCss();
                    }
                }

                checkHeaderPosition() {
                    const offset = ($(".sticky-nav-tabs").offset().top + $(".sticky-nav-tabs").height() - this.tabContainerHeight) - this.headerHeight;
                    if ($(window).scrollTop() > this.lastScroll && $(window).scrollTop() > offset) {
                        $(".sticky-nav-tabs-container").removeClass("sticky-nav-tabs-container--top-first");
                        $(".sticky-nav-tabs-container").addClass("sticky-nav-tabs-container--top-second");
                    }
                    else if ($(window).scrollTop() < this.lastScroll && $(window).scrollTop() > offset) {
                        $(".sticky-nav-tabs-container").removeClass("sticky-nav-tabs-container--top-second");
                        $(".sticky-nav-tabs-container").addClass("sticky-nav-tabs-container--top-first");
                    }
                    else {
                        $(".sticky-nav-tabs-container").removeClass("sticky-nav-tabs-container--top-first");
                        $(".sticky-nav-tabs-container").removeClass("sticky-nav-tabs-container--top-second");
                    }
                }

                findCurrentTabSelector(element) {
                    let newCurrentId;
                    let newCurrentTab;
                    let self = this;
                    $(".sticky-nav-tab").each(function () {
                        const id = $(this).attr("href");
                        const offsetTop = $(id).offset().top - self.tabContainerHeight - self.headerHeight;
                        const offsetBottom = $(id).offset().top + $(id).height() - self.tabContainerHeight - self.headerHeight;
                        if ($(window).scrollTop() > offsetTop && $(window).scrollTop() < offsetBottom) {
                            newCurrentId = id;
                            newCurrentTab = $(this);
                        }
                    });
                    if (this.currentId !== newCurrentId || this.currentId === null) {
                        this.currentId = newCurrentId;
                        this.currentTab = newCurrentTab;
                        this.setSliderCss();
                    }
                }

                setSliderCss() {
                    if (this.currentTab) {
                        $(".sticky-nav-tab").removeClass("active");
                        this.currentTab.addClass("active");
                    }
                }

                toggleMobileInvestorsMenu(event, element) {
                    event.preventDefault();
                    $(".investors-link-showHide").toggleClass("investors-link-down");
                    $(".mobile-investors-menu-toggle").slideToggle();
                    if ($(window).scrollTop() < 500) {
                        $("html, body").animate({ scrollTop: 500 }, 600);
                    }
                }

                closeMobileInvestorsMenu() {
                    $(".mobile-investors-menu-toggle").slideUp();
                    $(".investors-link-showHide").addClass("investors-link-down");
                }

                changeFinancialReportsYear(event, element) {
                    event.preventDefault();
                    $(".financial-reports-year").css("display", "none");
                    $("#financialReports" + element.val()).css("display", "block");
                }

                changePressReleasesYear(event, element) {
                    event.preventDefault();
                    $(".press-releases-year").css("display", "none");
                    $("#pressReleases" + element.val()).css("display", "block");
                }

                changeGovernanceReportsYear(event, element) {
                    event.preventDefault();
                    $(".governance-reports-year").css("display", "none");
                    $("#governanceReports" + element.val()).css("display", "block");
                }

                changeGeneralMeetingsYear(event, element) {
                    event.preventDefault();
                    $(".general-meetings-year").css("display", "none");
                    $("#generalMeetings" + element.val()).css("display", "block");
                }
            }

            new StickyNavigation();
        });
    }
}
﻿export default class TestimonialList {
    init() {

        $(".testimonial-item", ".show-neighbors").each(function () {
            var next = $(this).next();
            if (!next.length) {
                next = $(this).siblings(":first");
            }
            next.children(":first-child").clone().appendTo($(this));
        }).each(function () {
            var prev = $(this).prev();
            if (!prev.length) {
                prev = $(this).siblings(":last");
            }
            prev.children(":nth-last-child(2)").clone().prependTo($(this));
        });
        

        var itemsMainDiv = ('.MultiTestimonialCarousel');
        var itemsDiv = ('.MultiTestimonialCarousel-inner');
        var itemWidth = "";

        ResCarouselSize();

        $(window).resize(function () {
            ResCarouselSize();
        });

        //this function define the size of the items
        function ResCarouselSize() {
            var incno = 0;
            var dataItems = ("data-items");
            var itemClass = ('.item');
            var id = 0;
            var btnParentSb = '';
            var sampwidth = $(itemsMainDiv).width();
            var bodyWidth = $('body').width();
            $(itemsDiv).each(function () {
                id = id + 1;
                var itemNumbers = $(this).find(itemClass).length;
                btnParentSb = $(this).parent().attr(dataItems);
                itemsSplit = btnParentSb.split(',');
                $(this).parent().attr("id", "MultiTestimonialCarousel" + id);


                if (bodyWidth >= 768) {
                    incno = 2;
                    itemWidth = sampwidth / incno;
                }
                else {
                    incno = 1;
                    itemWidth = sampwidth / incno;
                }
                $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
                $(this).find(itemClass).each(function () {
                    $(this).outerWidth(itemWidth);
                });

                $(".testimonial-control-prev").addClass("over");
                $(".testimonial-control-next").removeClass("over");

            });
        }
    }
}
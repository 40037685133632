﻿export default class BootstrapColSlider {
    init() {
        this.colSlider();
    }

    colSlider() {

        function showHideArrows(e) {
            const colSliderContent = e.children('.bootstrap-col-slider-content');
            const colSliderLeft = e.children('.bootstrap-col-slider-left');
            const colSliderRight = e.children('.bootstrap-col-slider-right');
            const firstSlide = colSliderContent.children('.bootstrap-col-slider-scroll-content:first-child');
            const lastSlide = colSliderContent.children('.bootstrap-col-slider-scroll-content:last-child');
            if (colSliderContent.offset().left === firstSlide.offset().left) {
                colSliderLeft.fadeOut();
            } else {
                colSliderLeft.fadeIn();
            }
            if ((colSliderContent.offset().left + colSliderContent.width() + 5) >= (lastSlide.offset().left + parseInt(lastSlide.css("width"), 10))) {
                colSliderRight.fadeOut();
            } else {
                colSliderRight.fadeIn();
            }
        }

        function checkNeedForArrows() {
            $(".bootstrap-col-slider").each(function () {
                const colSlider = $(this);
                const colSliderContent = colSlider.find('.bootstrap-col-slider-content');
                const numberOfColumns = colSlider.find(".bootstrap-col-slider-scroll-content").length;
                const firstSlide = colSlider.find('.bootstrap-col-slider-scroll-content:first-child');
                if (colSlider.find('.bootstrap-col-slider-content').scrollLeft(0)) {
                    if ((numberOfColumns * firstSlide.width()) <= colSliderContent.width()) {
                        colSlider.find('.bootstrap-col-slider-left').hide();
                        colSlider.find('.bootstrap-col-slider-right').hide();
                    }
                    else {
                        colSlider.find('.bootstrap-col-slider-left').hide();
                        colSlider.find('.bootstrap-col-slider-right').show();
                    }
                }
            });
        }

        $(window).resize(function () {
            checkNeedForArrows();
        });
        $(document).ready(function() {
            checkNeedForArrows();
        });

        //scrollRightLeft([button clicked inside of wrapper], [scroll right(0)/left(1)]);
        function scrollColsRightLeft(e, direction) {
            const colPadding = parseInt(e.children(".bootstrap-col-slider-scroll-content:first-child").css('padding-right')) * 2;
            const wrapperOffset = e.offset().left;
            const scrollStep = Math.round(e.children(".bootstrap-col-slider-scroll-content:first-child").width() + colPadding);
            var scrollLeft = Math.round(-e.children(".bootstrap-col-slider-scroll-content:first-child").offset().left + wrapperOffset + (direction === 0 ? scrollStep : -scrollStep));
            
            if (scrollLeft % scrollStep !== 0) {
                scrollLeft -= (scrollLeft % scrollStep);
            }
            e.animate({ scrollLeft: scrollLeft }, 450);
            setTimeout(function () {
                showHideArrows(e.parent());
                const scrollToNumber = scrollLeft / scrollStep;
                if ($("[data-slide-to='" + scrollToNumber + "']").length) {
                    e.parent().children(".carousel-indicators").children("li").removeClass("active");
                    $("[data-slide-to='" + scrollToNumber + "']").addClass("active");
                }
            }, 500);
        }

        //scrollTo using carousel-indicators
        function scrollColsTo(e, scrollTo) {
            const colPadding = parseInt(e.children(".bootstrap-col-slider-scroll-content:first-child").css('padding-right')) * 2;
            const scrollLeft = Math.round(e.children(".bootstrap-col-slider-scroll-content:first-child").width() + colPadding) * scrollTo;

            e.animate({ scrollLeft: scrollLeft }, 450);
            setTimeout(function () {
                showHideArrows(e.parent());
            }, 500);
        }

        // scroll to right
        $(document).on('click', '.bootstrap-col-slider-right', function () {
            scrollColsRightLeft($(this).parent().children(".bootstrap-col-slider-content"), 0);
        });

        // scroll to left
        $(document).on('click', '.bootstrap-col-slider-left', function () {
            scrollColsRightLeft($(this).parent().children(".bootstrap-col-slider-content"), 1);
        });

        // Use scroll-navigators
        $(document).on('click', '.carousel-indicators li', function () {
            const slideTo = $(this).data("slide-to");
            $(this).parent().children().removeClass("active");
            $(this).addClass("active");
            scrollColsTo($(this).closest(".bootstrap-col-slider").children(".bootstrap-col-slider-content"), slideTo);
        });

        $('.bootstrap-col-slider-scroll-content').on('touchstart', function (event) {
            const xClick = event.originalEvent.touches[0].pageX;

            $(this).one('touchmove', function (event) {
                event.preventDefault();
                const xMove = event.originalEvent.touches[0].pageX;
                const sensitivityInPx = 5;
                const contentWrapper = $(this).parent();

                if (Math.floor(xClick - xMove) > sensitivityInPx) {
                    scrollColsRightLeft(contentWrapper, 0);
                }
                else if (Math.floor(xClick - xMove) < -sensitivityInPx) {
                    scrollColsRightLeft(contentWrapper, 1);
                }
            });
            $(this).on('touchend', function () {
                $(this).off('touchmove');
            });
        });
    }
}
﻿export default class HeaderLoggedInInfo {
    init() {

        function CloseLoggedIn() {
            $('.logged-in-info').fadeOut('slow');
            $('#logged-in-shadow-layer').removeClass('is-visible');
            $('.logged-in-trigger').removeClass('logged-in-trigger-active');
            $('#kundo-chat').show();
        }

        $(document).on('click', '#logged-in-shadow-layer', function () {
            CloseLoggedIn();
        });

        $(document).on('click', '.logged-in-trigger-active', function () {
            CloseLoggedIn();
            $('.logged-in-trigger').blur();
        });

        $(document).on('click', '.logged-in-trigger:not(.logged-in-trigger-active)', function () {
            $('.logged-in-info').slideDown();
            $('.logged-in-trigger').addClass('logged-in-trigger-active');
            $('#kundo-chat').hide();
            $('#logged-in-shadow-layer').addClass('is-visible');
        });
    }
}
﻿export default class ShareVideoButtonBlock {
    init() {
        this.shareVideoButton();
    }
    shareVideoButton() {
        function CopyFromVideoShareTextArea(text, button) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    button
                        .removeClass("btn-primary")
                        .addClass("btn-success mdi mdi-check")
                        .html("");
                })
                .catch((err) => {
                    console.error(`Error copying text to clipboard: ${err}`);
                });
        }

        $(document).on("click", ".copy-video-share-link", function () {
            const id = $(this).data("id");
            const text = $("#share-url-text" + id).val();
            const button = $("#copy-share-link" + id);
            CopyFromVideoShareTextArea(text, button);
        });

        $(document).on("click", ".copy-video-share-embed-code", function () {
            const id = $(this).data("id");
            const text = $("#share-embed-code" + id).val();
            const button = $("#copy-share-embed-code" + id);
            CopyFromVideoShareTextArea(text, button);
        });
    }
}
﻿import NotificationHelper from "./notification-helper";
import Footer from "./footer";
import CartItems from "./cart-items";
import MobileNavigation from "./mobile-navigation";
import ZinzinoRenderMenu from "./zinzino-render-menu";
import ZinzinoCarousel from "./zinzino-carousel";
import Selection from "./selection";
import Dropdown from "./dropdown";
import BootstrapColSlider from "./bootstrap-col-slider";
import BubbleInfoBlock from "Features/Blocks/BubbleInfoBlock/bubble-info-block";
import EventBlock from "Features/Blocks/EventBlock/event-block";
import ShareVideoButtonBlock from "Features/Blocks/ShareVideoButtonBlock/share-video-button-block";
import Investors from "Features/Investors/investors";
import NewsRoom from "Features/NewsRoom/news-room";
import ReplicatedSite from "./replicated-site";
import HeaderLoggedInInfo from "./header-logged-in-info";
import ReadMoreButton from "./read-more-button";
import TestimonialList from "Features/Blocks/TestimonialsListBlock/testimonial-list";


export default class FoundationCms {
    init() {
        // convert json to formdata and append __RequestVerificationToken key for CORS
        window.convertFormData = function (data, containerToken) {
            var formData = new FormData();
            var isAddedToken = false;
            for (var key in data) {
                if (key == "__RequestVerificationToken") {
                    isAddedToken = true;
                }
                formData.append(key, data[key]);
            }

            if (!isAddedToken) {
                if (containerToken) {
                    formData.append("__RequestVerificationToken", $(containerToken + ' input[name=__RequestVerificationToken]').val());
                } else {
                    formData.append("__RequestVerificationToken", $('input[name=__RequestVerificationToken]').val());
                }
            }

            return formData;
        };

        window.serializeObject = function (form) {
            var datas = form.serializeArray();
            var jsonData = {};
            for (var d in datas) {
                jsonData[datas[d].name] = datas[d].value;
            }

            return jsonData;
        };

        window.notification = new NotificationHelper();

        let bubbleInfoBlock = new BubbleInfoBlock();
        bubbleInfoBlock.init();

        let eventBlock = new EventBlock();
        eventBlock.init();

        let investors = new Investors();
        investors.init();

        let newsRoom = new NewsRoom();
        newsRoom.init();

        const params = {
            sideBarId: "#offside-menu-mobile",
            openSideBarId: "#open-offside-menu"
        }

        let cartItems = new CartItems();
        cartItems.init();

        let mobileNavigation = new MobileNavigation(params);
        mobileNavigation.init();

        let zinzinoRenderMenu = new ZinzinoRenderMenu();
        zinzinoRenderMenu.init();

        let zinzinoCarousel = new ZinzinoCarousel();
        zinzinoCarousel.init();

        let selection = new Selection();
        selection.init();

        let shareVideoButtonBlock = new ShareVideoButtonBlock();
        shareVideoButtonBlock.init();

        let dropdown = new Dropdown();
        dropdown.init();

        let bootstrapColSlider = new BootstrapColSlider();
        bootstrapColSlider.init();

        let footer = new Footer();
        footer.init();

        let replicatedSite = new ReplicatedSite();
        replicatedSite.init();

        let readMoreButton = new ReadMoreButton();
        readMoreButton.init();

        let testimonialList = new TestimonialList();
        testimonialList.init();

        let headerLoggedInInfo = new HeaderLoggedInInfo();
        headerLoggedInInfo.init();
    }
}